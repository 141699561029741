<template>
    <v-skeleton-loader v-if="!gesuch" type="list-item-avatar, list-item-three-line@3, actions"></v-skeleton-loader>
    <div v-else>
        <v-toolbar flat color="primary white--text" class="colored-toolbar" min-height="72px">
            <v-btn icon color="white" :to="{ name: 'gesuche' }">
                <v-icon>fas fa-arrow-left</v-icon>
            </v-btn>
            <div class="ml-2">
                <div>
                    <v-toolbar-title>
                        <v-tooltip right>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    v-if="
                                        gesuch.gueltigkeit_zusage_in_tagen !== null &&
                                        gesuch.gueltigkeit_zusage_in_tagen < 0 &&
                                        !gesuch.isClosed()
                                    "
                                    color="red"
                                    class="pt-1"
                                    v-on="on"
                                >
                                    fas fa-exclamation-circle
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        gesuch.gueltigkeit_zusage_in_tagen !== null &&
                                        gesuch.gueltigkeit_zusage_in_tagen <=
                                            $store.state.config.gueltigkeit_zusage_deadline &&
                                        !gesuch.isClosed()
                                    "
                                    color="orange"
                                    class="pt-1"
                                    v-on="on"
                                >
                                    fas fa-exclamation-circle
                                </v-icon>
                                <v-icon
                                    v-else-if="
                                        gesuch.gueltigkeit_zusage_in_tagen !== null &&
                                        gesuch.gueltigkeit_zusage_in_tagen >
                                            $store.state.config.gueltigkeit_zusage_deadline &&
                                        !gesuch.isClosed()
                                    "
                                    color="green"
                                    class="pt-1"
                                    v-on="on"
                                >
                                    fas fa-circle
                                </v-icon>
                            </template>
                            <span v-if="gesuch.gueltigkeit_zusage_in_tagen > 0">{{
                                $t('zusage_deadline_in_days', {
                                    days: gesuch.gueltigkeit_zusage_in_tagen,
                                    date: formatDate(gesuch.gueltigkeit_zusage)
                                })
                            }}</span>
                            <span v-else-if="gesuch.gueltigkeit_zusage_in_tagen === 0">{{
                                $t('zusage_deadline_today', {
                                    date: formatDate(gesuch.gueltigkeit_zusage)
                                })
                            }}</span>
                            <span v-else>{{
                                $t('zusage_deadline_expired_since', {
                                    days: Math.abs(gesuch.gueltigkeit_zusage_in_tagen),
                                    date: formatDate(gesuch.gueltigkeit_zusage)
                                })
                            }}</span>
                        </v-tooltip>
                        {{ gesuch.nummer }}
                        <span v-if="locked && !readOnly" class="locked">
                            <v-icon color="red" x-small> fas fa-lock</v-icon>
                            {{ $t('locked') }}
                        </span>
                        <span v-if="readOnly" class="read-only">
                            <v-icon color="yellow" x-small>far fa-eye</v-icon>
                            {{ $t('read_only') }}
                        </span>
                    </v-toolbar-title>
                </div>
                <div class="subtitle-2">
                    {{ gesuch.foerderkategorie_apollon }}
                    |
                    {{ $t('created') }}: {{ gesuch.created.format('L') }}
                    <span v-if="gesuch.gesuch_erstmals_eingereicht_datum.isValid()">
                        | {{ $t('submitted') }}: {{ gesuch.gesuch_erstmals_eingereicht_datum.format('L') }}
                    </span>
                    <span v-if="gesuch.foerderzusage_datum.isValid()">
                        | {{ $t('foerderzusage') }}: {{ gesuch.foerderzusage_datum.format('L') }}
                    </span>
                    <span v-if="gesuch.projektabschluss_erstmals_eingereicht_datum.isValid()">
                        | {{ $t('project_done') }}: {{ gesuch.projektabschluss_erstmals_eingereicht_datum.format('L') }}
                    </span>
                    <StatusLabel :status="gesuch.status" badge />
                </div>
            </div>
            <v-spacer />
            <div v-if="gesuch.rolle" class="mr-5">Ihre Rolle: {{ $t(gesuch.rolle) }}</div>
            <img v-if="gesuch.kanton === 'SH'" alt="Wappen SH" width="48px" :src="shImg" class="mt-2" />
            <img v-if="gesuch.kanton === 'TG'" alt="Wappen TG" width="48px" :src="tgImg" class="mt-2" />
        </v-toolbar>

        <NavigationToggleGroup :value="step" :items="steps.filter(isStepVisible)" :loading="saving" />

        <NextActionBanner :gesuch="gesuch" />
        <v-alert v-if="gesuch.isLocked(user)" class="ma-1" outlined color="error" icon="far fa-eye">
            <strong>{{ $t('gesuch_readonly') }}</strong>
        </v-alert>

        <div
            v-if="stepObject"
            :class="{
                'd-block': true,
                'd-md-none': true
            }"
        >
            <h6 class="pr-3 pl-3 grey lighten-3 black--text text-h6">{{ stepObject.text }}</h6>
        </div>

        <v-slide-x-reverse-transition leave-absolute>
            <GesuchCardAdressen
                v-if="step === 'adressen'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @gesuchdatachanged="onGesuchDataChanged"
                @next="next()"
            />
            <GesuchCardLiegenschaften
                v-else-if="step === 'liegenschaften'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @editingmode="(e) => (liegenschaftEditingMode = e)"
                @gesuchdatachanged="onGesuchDataChanged"
                @next="next()"
            />
            <GesuchCardProjekt
                v-else-if="step === 'projekt'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @gesuchdatachanged="onGesuchDataChanged"
                @next="next()"
            />
            <GesuchCardDokumente
                v-else-if="step === 'dokumente'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @gesuchdatachanged="onGesuchDataChanged"
                @next="next()"
            />
            <GesuchCardBedingungen
                v-else-if="step === 'bedingungen'"
                key="bedingungen"
                :gesuch="gesuch"
                type="foerderbedingungen"
                :loading="saving"
                :read-only="readOnly"
                @next="next()"
            />
            <GesuchCardBedingungen
                v-else-if="step === 'foerdersaetze'"
                key="foerdersaetze"
                type="foerdersaetze"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @next="next()"
            />
            <GesuchCardKontoinformation
                v-else-if="step === 'kontoinformation'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @next="next()"
            />
            <GesuchCardAbschluss
                v-else-if="step === 'abschluss'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
                @gesuchdatachanged="onGesuchDataChanged"
                @next="abschluss"
            />
            <GesuchCardNachrichten
                v-else-if="step === 'nachrichten'"
                :gesuch="gesuch"
                :loading="saving"
                :read-only="readOnly"
            />
        </v-slide-x-reverse-transition>
    </div>
</template>

<script>
/**
 * This is the main panel for a single Gesuch (edit panel).
 */
import NavigationToggleGroup from 'js/components/navigation-toggle-group.vue';
import GesuchCardAdressen from 'js/views/auth/gesuch/GesuchAdressenCard.vue';
import GesuchCardBedingungen from 'js/views/auth/gesuch/GesuchBedingungenCard.vue';
import GesuchCardProjekt from 'js/views/auth/gesuch/GesuchProjektCard.vue';
import GesuchCardLiegenschaften from 'js/views/auth/gesuch/GesuchLiegenschaftenCard.vue';
import GesuchCardDokumente from 'js/views/auth/gesuch/GesuchDokumenteCard.vue';
import GesuchCardAbschluss from 'js/views/auth/gesuch/GesuchAbschlussCard.vue';
import GesuchCardNachrichten from 'js/views/auth/gesuch/GesuchNachrichtenCard.vue';
import GesuchCardKontoinformation from 'js/views/auth/gesuch/GesuchKontoinformationCard.vue';
import StatusLabel from 'js/components/gesuch-status-label.vue';
import NextActionBanner from 'js/components/next-action-banner.vue';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images';
import { mapState, mapActions } from 'vuex';
import { routeGuardFormConfirmMixin, errToStr } from 'js/lib/helpers.js';
import { instance as api } from 'js/lib/api';
import dayjs from 'dayjs';
import { PHASE } from 'js/models/Gesuch';

export default {
    components: {
        NavigationToggleGroup,
        GesuchCardAdressen,
        GesuchCardBedingungen,
        GesuchCardProjekt,
        GesuchCardLiegenschaften,
        GesuchCardDokumente,
        GesuchCardKontoinformation,
        GesuchCardAbschluss,
        GesuchCardNachrichten,
        StatusLabel,
        NextActionBanner
    },
    mixins: [routeGuardFormConfirmMixin],
    data: function () {
        return {
            tgImg: TgWappenSvg,
            shImg: ShWappenSvg,
            liegenschaftEditingMode: false,
            saving: false,
            step: 'adressen',
            steps: [
                {
                    icon: 'far fa-address-book',
                    value: 'adressen',
                    to: { name: 'gesuch' },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('adressen')
                },
                {
                    icon: 'far fa-building',
                    value: 'liegenschaften',
                    to: { name: 'gesuch-step', params: { step: 'liegenschaften' } },
                    showWhenReadonly: true,
                    enabled: true,
                    enabledFn: () => this.liegenschaftenVisible,
                    text: this.$t('liegenschaften')
                },
                {
                    icon: 'fas fa-tasks',
                    value: 'projekt',
                    to: { name: 'gesuch-step', params: { step: 'projekt' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('project')
                },
                {
                    icon: 'far fa-file-pdf',
                    value: 'dokumente',
                    to: { name: 'gesuch-step', params: { step: 'dokumente' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('documents')
                },
                {
                    icon: 'fas fa-code-branch',
                    value: 'bedingungen',
                    to: { name: 'gesuch-step', params: { step: 'bedingungen' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('foerderbedingungen'),
                    preventSaveOnExit: true
                },
                {
                    icon: 'fas fa-square-root-alt',
                    value: 'foerdersaetze',
                    to: { name: 'gesuch-step', params: { step: 'foerdersaetze' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('foerdersaetze'),
                    preventSaveOnExit: true
                },
                {
                    icon: 'far fa-credit-card',
                    value: 'kontoinformation',
                    to: { name: 'gesuch-step', params: { step: 'kontoinformation' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('kontoinformation'),
                    phases: [PHASE.ABSCHLUSS]
                },
                {
                    icon: 'far fa-paper-plane',
                    value: 'abschluss',
                    to: { name: 'gesuch-step', params: { step: 'abschluss' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('abschluss')
                },
                {
                    icon: 'far fa-envelope',
                    value: 'nachrichten',
                    to: { name: 'gesuch-step', params: { step: 'nachrichten' } },
                    showWhenReadonly: true,
                    enabled: true,
                    text: this.$t('messages')
                }
            ]
        };
    },
    computed: {
        ...mapState('gesuch', {
            gesuch: 'actGesuch',
            loading: 'loading'
        }),
        ...mapState('benutzer', {
            user: 'record'
        }),
        locked() {
            return this.gesuch.isLocked(this.user);
        },
        /* readOnly is a calculated prop for every state that causes this Gesuch to be non-editable for the actual user */
        readOnly() {
            // Adapt if more things need to be considered:
            return this.locked === true || this.gesuch.isReadOnly();
        },
        stepObject() {
            for (let obj of this.steps) {
                if (obj.value === this.step) {
                    return obj;
                }
            }
            return null;
        },
        liegenschaftenVisible() {
            if (this.gesuch) {
                return this.gesuch.config.config.objekte.visible;
            }
            return true;
        }
    },
    watch: {
        // call again the method if the route changes
        $route(route, oldRoute) {
            this.next(route.params.step || 'adressen');

            // skip if the route has not changed:
            if (oldRoute && oldRoute.params.nr === route.params.nr) {
                return;
            }
            if (oldRoute && oldRoute.params.nr) {
                this.releaseLock(oldRoute.params.nr);
            }
            this.load(route.params.nr);
        }
    },

    async created() {
        let loaded = await this.load(this.$route.params.nr);
        if (loaded) {
            this.registerLockRefreshTimer();
            this.renumberSteps();
            this.step = this.$route.params.step || 'adressen';
        } else {
            this.$router.go(-1);
        }
    },

    beforeDestroy() {
        this.unregisterLockRefreshTimer();
        if (this.gesuch) {
            this.releaseLock(this.gesuch.nummer);
        }
    },

    methods: {
        ...mapActions('gesuch', ['storeGesuch', 'loadGesuch']),
        registerLockRefreshTimer() {
            this.unregisterLockRefreshTimer();
            // fires every 10 minutes:
            console.info('Starting Gesuch Lock Timer');
            this.lockRefreshTimer = setInterval(() => {
                if (this.gesuch) {
                    this.refreshLock(this.gesuch.nummer);
                }
            }, 1000 * 60 * 10);
        },

        unregisterLockRefreshTimer() {
            if (this.lockRefreshTimer) {
                clearInterval(this.lockRefreshTimer);
                this.lockRefreshTimer = null;
                console.info('Stopping Gesuch Lock Timer');
            }
        },

        async refreshLock(gesuchNr) {
            if (this.readOnly) {
                console.info('Not executing Gesuch lock, as this Gesuch is readonly: ' + gesuchNr);
                return;
            }
            try {
                console.info('Refreshing lock on Gesuch ' + gesuchNr);
                await api().get('/gesuch/acquireLock', {
                    params: {
                        gesuch_nummer: gesuchNr
                    }
                });
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },

        async releaseLock(gesuchNr) {
            try {
                console.info('Releasing lock on Gesuch ' + gesuchNr);
                await api().get('/gesuch/releaseLock', {
                    params: {
                        gesuch_nummer: gesuchNr
                    }
                });
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
            }
        },

        async load(gesuchNr) {
            try {
                await this.loadGesuch(gesuchNr);
                return true;
            } catch (e) {
                this.$toast({ message: errToStr(e), color: 'error' });
                return false;
            }
        },

        onGesuchDataChanged() {
            this.saveGesuch();
        },

        async saveGesuch() {
            try {
                this.saving = true;
                if (!this.readOnly) {
                    await this.storeGesuch(this.gesuch);
                    this.$toast({ message: 'Daten erfolgreich gespeichert', color: 'success' });
                }
            } catch (res) {
                this.$toast({ message: errToStr(res), color: 'error' });
            } finally {
                this.saving = false;
            }
        },

        async next(step) {
            let actStepFound = false;
            let save = true;
            let newStep = 'adressen';

            let current = this.findStepByValue(this.step);
            if (current && current.preventSaveOnExit) {
                save = false;
            }

            // find the NEXT step of the actual step, or use the given one:
            if (!step) {
                this.steps.forEach((s) => {
                    if (actStepFound) {
                        if (this.isStepVisible(s)) {
                            newStep = s.value;
                            actStepFound = false;
                        }
                    }
                    if (s.value === this.step) {
                        actStepFound = true;
                    }
                });
            } else {
                newStep = step;
            }
            if (this.step !== newStep) {
                if (save) {
                    await this.saveGesuch();
                }
                this.step = newStep;
                let stepObj = this.findStepByValue(newStep);
                if (stepObj.to.params.step !== this.$router.currentRoute.params.step) {
                    this.$router.push(stepObj.to).catch(() => console.log('oops, not routed'));
                }
            }
        },

        findStepByValue(value) {
            for (let s of this.steps) {
                if (s.value === value) {
                    return s;
                }
            }
            return null;
        },

        getFilteredSteps() {
            return this.steps.filter((i) => {
                let vis = i.showWhenReadonly || !this.readOnly;
                if (i.phases && i.phases.length > 0) {
                    vis = vis && i.phases.indexOf(this.gesuch.phase) >= 0;
                }
                return vis;
            });
        },

        isStepVisible(step) {
            let vis = step.showWhenReadonly || !this.readOnly;
            if (typeof step.enabledFn === 'function') {
                vis &= step.enabledFn();
            }
            if (step.phases && step.phases.length > 0) {
                vis = vis && step.phases.indexOf(this.gesuch.phase) >= 0;
            }
            return vis;
        },

        abschluss() {
            this.$router.push({ name: 'gesuche' });
        },

        formatDate(value) {
            return dayjs(value).format('DD.MM.YYYY');
        },

        renumberSteps() {
            var drop = 0;
            for (let i = 0; i < this.steps.length; i++) {
                let step = this.steps[i];
                if (!this.isStepVisible(step) && step.avatar) {
                    drop++;
                } else if (step.avatar) {
                    step.avatar = '' + (parseInt(step.avatar, 10) - drop);
                }
            }
        },

        isDirty() {
            if (this.readOnly) {
                return false;
            }
            return this.liegenschaftEditingMode;
        }
    }
};
</script>

<style lang="scss" scoped>
.locked {
    color: red;
    font-size: 0.8em;
    text-shadow: 0 0.2pt 0 black;
}

.read-only {
    color: yellow;
    font-size: 0.8em;
    text-shadow: 0 0.2pt 0 black;
}
</style>
