<template>
    <div>
        <v-toolbar color="primary white--text" class="colored-toolbar">
            <v-toolbar-title>
                <v-icon left color="white">fas fa-key</v-icon>{{ $t('change_my_password') }}
            </v-toolbar-title>
        </v-toolbar>
        <v-container fluid>
            <v-alert type="info" :icon="false" border="left" colored-border elevation="2">
                <span>{{ $t('new_password_requires') }}</span>
                <ul>
                    <li>{{ $t('at_least_eight_characters') }}</li>
                    <li>{{ $t('at_least_one_letter') }}</li>
                    <li>{{ $t('at_least_one_digit') }}</li>
                    <li>{{ $t('diffrent_to_last_password') }}</li>
                </ul>
                <div>{{ $t('for_example') + ": 'abcd1234'" }}</div>
            </v-alert>
            <kp-textfield v-model="oldPw" type="password" :label="$t('old_password')" />
            <kp-textfield
                v-model="newPw"
                type="password"
                :label="$t('new_password')"
                :rules="[formRules.minLength(8)]"
            />
            <kp-textfield
                v-model="newPwConfirm"
                type="password"
                :label="$t('new_password_confirm')"
                :error-messages="passwordsValid ? null : $t('pw_not_match')"
            />
            <v-btn
                color="action white--text"
                :disabled="oldPw.length < 1 || !passwordsValid || loading"
                @click="onChangePW"
            >
                <v-icon>fas fa-key</v-icon>
                &nbsp;{{ $t('change_my_password') }}
            </v-btn>
        </v-container>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { instance as getApi } from 'js/lib/api.js';
import { errToStr } from 'js/lib/helpers.js';
import { formRules } from 'js/lib/helpers.js';
import KpTextfield from 'js/components/kp-textfield.vue';

export default {
    components: {
        KpTextfield
    },
    data: function () {
        return {
            formRules,
            loading: false,
            oldPw: '',
            newPw: '',
            newPwConfirm: ''
        };
    },
    computed: {
        passwordsValid() {
            return this.newPw.length > 0 && this.newPw === this.newPwConfirm;
        }
    },
    methods: {
        ...mapActions(['runAsync']),
        async onChangePW() {
            this.loading = true;
            this.runAsync(async () => {
                try {
                    await getApi().post('/auth/changePassword', {
                        old_password: this.oldPw,
                        new_password: this.newPw
                    });
                    this.$toast({ message: this.$t('pw_change_successful'), color: 'success' });
                    this.$router.push({ name: 'home' });
                } catch (e) {
                    this.$toast({ message: errToStr(e), color: 'error' });
                } finally {
                    this.loading = false;
                }
            });
        }
    }
};
</script>
