<template>
    <v-dialog :value="value" persistent :max-width="700" @click:outside="$emit('close')">
        <v-card>
            <slot name="title">
                <v-card-title>{{ $t('hilfe_kontakt') }}</v-card-title>
            </slot>
            <slot>
                <v-card-text>
                    <h3>Haben Sie technische Fragen zum Projekt oder zu einem bestimmten Gesuch?</h3>
                    <p>Dann wenden Sie sich an:</p>
                    <div class="d-flex">
                        <img :src="tgImg" height="24" alt="Thurgau Wappen" style="margin-right: 5px" />
                        <h4>Kanton Thurgau</h4>
                    </div>

                    <ul>
                        <li>
                            GEAK mit Beratungsbericht, Gebäudehüllensanierungen, Ersatz Schaufenster, Modernisierung
                            nach GEAK-Effizienzklassen, Komfortlüftungsanlagen: Energiefachleute Thurgau (EFT),
                            <kp-link mailto href="info@energie-thurgau.ch" />, <kp-link tel href="052 577 11 80" />
                        </li>
                        <li>
                            Holzfeuerungen, Wärmenetzanschlüsse: Nova Energie Ostschweiz AG,
                            <kp-link mailto href="sirnach@novaenergie.ch" />, <kp-link tel href="052 368 08 08" />
                        </li>
                        <li>
                            Wärmepumpenanlagen: esolva ag, <kp-link mailto href="martin.scheiba@esolva.ch" />,
                            <kp-link tel href="058 458 61 17" />
                        </li>
                        <li>
                            Batteriespeicher: Solarstrom-Pool Thurgau,
                            <kp-link mailto href="info@solarstrom-pool.ch" />,
                            <kp-link tel href="052 724 03 48" />
                        </li>
                        <li>
                            Elektrofahrzeuge, Erschliessung Ladeinfrastruktur: AWEP GmbH,
                            <kp-link mailto href="andreas.wieland@awep.ch" />, <kp-link tel href="052 511 51 84" />
                        </li>
                        <li>
                            Alle übrigen Bereiche: Amt für Energie, <kp-link mailto href="energie@tg.ch" />,
                            <kp-link tel href="058 345 54 80" />
                        </li>
                    </ul>

                    <div class="d-flex mt-2">
                        <img :src="shImg" height="24" alt="Schaffhausen Wappen" style="margin-right: 5px" />
                        <h4>Kanton Schaffhausen</h4>
                    </div>
                    <div v-html="shHelp"></div>
                    <ul>
                        <li>
                            Gebäudehüllensanierungen: Energiefachleute Schaffhausen, Irene Walter,
                            <kp-link mailto href="gebaeudesanierung@bluewin.ch" />, <kp-link tel href="052 620 33 43" />
                        </li>
                        <li>
                            Modernisierung nach GEAK-Effizienzklassen, Holzfeuerungen, Wärmepumpenanlagen,
                            Wärmenetzanschlüsse, thermische Solaranlagen, Komfortlüftungsanlagen: bollinger Energy
                            Design | Consulting,
                            <kp-link mailto href="a.memeti@bollinger.info" />,
                            <kp-link tel href="052 533 66 04" />
                        </li>
                        <li>
                            Batteriespeicher: Solarstrom-Pool Thurgau,
                            <kp-link mailto href="info@solarstrom-pool.ch" />, <kp-link tel href="052 724 03 48" />
                        </li>
                        <li>
                            Elektrofahrzeuge, Erschliessung Ladeinfrastruktur: AWEP GmbH,
                            <kp-link mailto href="andreas.wieland@awep.ch" />, <kp-link tel href="052 511 51 84" />
                        </li>
                        <li>
                            Alle übrigen Bereiche: Energiefachstelle,
                            <kp-link mailto href="energiefachstelle@sh.ch" />, <kp-link tel href="052 632 76 37" />
                        </li>
                    </ul>

                    <h3>Haben Sie technische Fragen zum Energieförderportal?</h3>
                    <p>Dann wenden Sie sich an:</p>
                    <div v-html="afeHelp"></div>
                </v-card-text>
            </slot>
            <slot name="actions">
                <v-card-actions>
                    <v-btn color="decline" text :loading="loading" @click="$emit('close')">
                        {{ $t('close') }}
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
import link from 'js/components/link.vue';
import { TgWappenSvg, ShWappenSvg } from 'js/lib/images.js';

export default {
    components: {
        'kp-link': link
    },
    props: {
        value: { type: Boolean, default: false },
        loading: { type: Boolean, default: false }
    },
    data() {
        return {
            tgImg: TgWappenSvg,
            shImg: ShWappenSvg,
            tgHelp: '',
            shHelp: '',
            afeHelp: ''
        };
    },
    async mounted() {
        this.tgHelp = await (await fetch(this.$store.state.webroot + '/static/helptg.html')).text();
        this.shHelp = await (await fetch(this.$store.state.webroot + '/static/helpsh.html')).text();
        this.afeHelp = await (await fetch(this.$store.state.webroot + '/static/helpafe.html')).text();
    }
};
</script>

<style scoped>
.v-card__text,
.v-card__title {
    word-break: normal !important;
}

h3 {
    margin-top: 1.5em;
}

address {
    font-style: normal;
}
</style>
