/**
 * This file is part of Zeus Apollon
 *
 * (c) Kaden und Partner AG
 */
import BaseModel from './BaseModel';
import BaseCollection from './BaseCollection';
import dayjs from 'dayjs';
import { ApollonConfFk } from './ApollonConfFk';
import { ZeusDokument } from 'js/models/zeus/ZeusDokument';
import { getStore } from 'js/store/index.js';

export const STATUS = {
    AB_APPROVED: 'ab_approved',
    AB_CANCELED: 'ab_canceled',
    AB_IN_PROGRESS: 'ab_in_progress',
    AB_INCOMPLETE: 'ab_incomplete',
    AB_REJECTED: 'ab_rejected',
    AB_SUBMIT: 'ab_submit',
    APPROVED: 'approved',
    CANCELED: 'canceled',
    CLOSED: 'closed',
    IN_PROGRESS: 'in_progress',
    INCOMPLETE: 'incomplete',
    NEW: 'new',
    READY_FOR_SUBMIT: 'ready_for_submit',
    AB_READY_FOR_SUBMIT: 'ab_ready_for_submit',
    REJECTED: 'rejected',
    SUBMIT: 'submit'
};

export const PHASE = {
    EINREICHUNG: 'einreichung',
    ABSCHLUSS: 'abschluss',
    FERTIG: 'fertig'
};

export class Gesuch extends BaseModel {
    defaults() {
        return {
            id: null,
            ersteller_id: null,
            bearbeiter_id: null,
            nummer: null,
            status: null,
            gesuchs_vorlage_id: null,
            foerderkategorie_id: null,
            kanton_id: null,
            data: null,
            lock: null,
            lock_benutzer_id: null,

            // View fields:
            gesuchs_vorlage: null,
            foerderkategorie: null,
            foerderkategorie_apollon: null,
            kanton: null,
            eigentuemer_display_name: null,
            eigentuemer_organisation_name: null,
            eigentuemer_vorname: null,
            eigentuemer_nachname: null,
            eigentuemer_plz: null,
            eigentuemer_ort: null,
            eigentuemer_laendercode: null,
            eigentuemer_adresse: null,
            eigentuemer_email: null,
            eigentuemer_benutzer_id: null,
            unternehmer_display_name: null,
            unternehmer_organisation_name: null,
            unternehmer_vorname: null,
            unternehmer_nachname: null,
            unternehmer_plz: null,
            unternehmer_ort: null,
            unternehmer_laendercode: null,
            unternehmer_adresse: null,
            unternehmer_email: null,
            unternehmer_benutzer_id: null,
            bevollmaechtigter_display_name: null,
            bevollmaechtigter_organisation_name: null,
            bevollmaechtigter_vorname: null,
            bevollmaechtigter_nachname: null,
            bevollmaechtigter_plz: null,
            bevollmaechtigter_ort: null,
            bevollmaechtigter_laendercode: null,
            bevollmaechtigter_adresse: null,
            bevollmaechtigter_email: null,
            bevollmaechtigter_benutzer_id: null,
            phase: null,
            gesuch_eingereicht_datum: null,
            gesuch_erstmals_eingereicht_datum: null,
            foerderzusage_datum: null,
            projektabschluss_eingereicht_datum: null,
            projektabschluss_erstmals_eingereicht_datum: null,
            zahlung_datum: null,
            anzahl_liegenschaften: 0,
            gueltigkeit_zusage: null,
            gueltigkeit_zusage_in_tagen: null
        };
    }

    mutations() {
        return {
            id: (id) => (Number(id) > 0 ? Number(id) : null),
            eigentuemer_benutzer_id: (id) => (Number(id) > 0 ? Number(id) : null),
            unternehmer_benutzer_id: (id) => (Number(id) > 0 ? Number(id) : null),
            bevollmaechtigter_benutzer_id: (id) => (Number(id) > 0 ? Number(id) : null),
            created: dayjs,
            modified: dayjs,
            gesuch_eingereicht_datum: dayjs,
            gesuch_erstmals_eingereicht_datum: dayjs,
            foerderzusage_datum: dayjs,
            projektabschluss_eingereicht_datum: dayjs,
            projektabschluss_erstmals_eingereicht_datum: dayjs,
            zahlung_datum: dayjs,
            gueltigkeit_zusage: dayjs,
            gueltigkeit_zusage_in_tagen: (days) => (days === null ? null : Number(days))
        };
    }

    entityName() {
        return 'gesuch';
    }

    /**
     * Returns true if the Gesuch is actually locked for the given ApollonBenutzer.
     * @param {ApollonBenutzer} user
     */
    isLocked(user) {
        return this.lock_benutzer_id && user && user.id !== this.lock_benutzer_id;
    }

    isClosed() {
        return this.status === STATUS.CANCELED || this.status === STATUS.AB_CANCELED || this.status === STATUS.CLOSED;
    }

    isReadOnly() {
        if (getStore().getters.getBenutzer.rolle === 'admin' && this.status !== this.isClosed()) {
            return false;
        }
        return (
            this.status === STATUS.AB_IN_PROGRESS ||
            this.status === STATUS.AB_SUBMIT ||
            this.status === STATUS.CANCELED ||
            this.status === STATUS.AB_CANCELED ||
            this.status === STATUS.CLOSED ||
            this.status === STATUS.IN_PROGRESS ||
            this.status === STATUS.SUBMIT ||
            this.status === STATUS.AB_APPROVED
        );
    }

    // dynamic config from zeus.apollon_fk_config.config from the load action
    get config() {
        if (!this._config) {
            this._config = new ApollonConfFk(null);
        }
        return this._config;
    }

    set config(newConf) {
        this._config = new ApollonConfFk(newConf);
    }

    // dynamic zeus_dokumente config from the load action
    get zeus_dokumente() {
        if (!this._zeus_dokumente) {
            this._zeus_dokumente = [];
        }
        return this._zeus_dokumente;
    }

    set zeus_dokumente(newConf) {
        this._zeus_dokumente = [];
        if (newConf instanceof Array) {
            newConf.forEach((dokConf) => {
                this._zeus_dokumente.push(new ZeusDokument(dokConf));
            });
        }
    }

    get rolle() {
        const bId = getStore().getters.getBenutzer.id;
        if (this.eigentuemer_benutzer_id === bId) {
            return 'eigentuemer';
        }
        if (this.bevollmaechtigter_benutzer_id === bId) {
            return 'bevollmaechtigter';
        }
        if (this.unternehmer_benutzer_id === bId) {
            return 'unternehmer';
        }
        if (this.ersteller_id === bId) {
            return 'ersteller';
        }
        return null;
    }
}
export class GesuchCollection extends BaseCollection {
    options() {
        return {
            model: Gesuch
        };
    }

    entityName() {
        return 'gesuch';
    }
}
